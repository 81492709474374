import React from "react"
import { Helmet } from "react-helmet"
import Header from "./Header"
import View from "./View"
import PropTypes from "prop-types"

const Layout = ({ children }) => (
  <div className="morna-addict">
    <Helmet title="MornaAddict" />
    <Header />
    <main className="morna-addict__content">
      <View>{children}</View>
    </main>
  </div>
)

Layout.propTypes = {
  children: PropTypes.element.isRequired,
}
export default Layout
