import React, { useState } from "react"
import { Menu, MenuItem, Toolbar } from "@mui/material"
import { navigate } from "gatsby"
import MenuIcon from "@mui/icons-material/Menu"

import { menuConfig } from "../menuBarConfiguration"

const MobileMenuBar = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  return (
    <Toolbar className="morna-addict-small-menu-bar">
      <MenuIcon onClick={handleClick} />
      <Menu
        classes={{
          paper: "morna-addict-small-menu-bar__menu-item",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        {menuConfig.map(item => (
          <MenuItem
            classes={{
              root: "morna-addict-small-menu-bar-button",
            }}
            className={""}
            onClick={() => {
              setAnchorEl(null)
              navigate(item.path)
            }}
            key={item.id}
          >
            <div className="morna-addict-small-menu-bar-button__icon">
              {item.icon}
            </div>
            <div className="morna-addict-small-menu-bar-button__text">
              {item.title}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </Toolbar>
  )
}

export default MobileMenuBar
