import React from "react"
import { Link } from "gatsby"

import logoSVG from "../../../images/logo.svg"
import MenuBar from "./Menu/MenuBar"
import MobileMenuBar from "./Menu/MobileLayout/MobileMenuBar"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Header = () => {
  const breakpoints = useBreakpoint()
  return (
    <div className={"morna-addict-header"}>
      <div className={"morna-addict-header-container"}>
        <Link css={"plain-link"} to={"/"}>
          <img
            alt="logo"
            className={"morna-addict-header-container__logo"}
            src={logoSVG}
          />
        </Link>
      </div>
      <div
        className={`morna-addict-header__app-bar${breakpoints.sm ? "--small" : ""}`}
      >
        {breakpoints.sm ? <MobileMenuBar /> : <MenuBar />}
      </div>
    </div>
  )
}

export default Header
