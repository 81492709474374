import React from "react"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import HelpIcon from "@mui/icons-material/Help"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import CalculatorIcon from "../../../../images/icons/CalculatorIcon"
import WikiIcon from "../../../../images/icons/WikiIcon"

const buttonTypes = {
  HOME: "HOME",
  CALC: "CALCULATOR",
  MOB: "MOB_SEARCH",
  WIKI: "WIKI",
  DONATE: "DONATE",
}

const { HOME, CALC, MOB, WIKI, DONATE } = buttonTypes

const titleMapping = {
  [HOME]: "Home",
  [CALC]: "Calculators",
  [MOB]: "Mob Search",
  [WIKI]: "Wiki",
  [DONATE]: "Donate",
}
const urlMapping = {
  [HOME]: "/",
  [CALC]: "/calculator/",
  [MOB]: "/daily/",
  [WIKI]: "https://wiki.mornaaddict.org/",
  [DONATE]:
    "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=2FJH4CYVXSV5G&source=url",
}
const iconMapping = {
  [HOME]: HomeOutlinedIcon,
  [CALC]: CalculatorIcon,
  [MOB]: HelpIcon,
  [WIKI]: WikiIcon,
  [DONATE]: MonetizationOnIcon,
}

export const menuConfig = [HOME, CALC, MOB, WIKI, DONATE].map((type, index) => {
  const Icon = iconMapping[type]
  return {
    id: index,
    title: titleMapping[type],
    icon: <Icon style={{ paddingRight: "5px" }} />,
    path: urlMapping[type],
  }
})
