import React from "react"
import { Link } from "gatsby"
import { menuConfig } from "./menuBarConfiguration"

const MenuBar = () => (
  <div className="morna-addict-menu-bar">
    {menuConfig.map(item => {
      return (
        <div className="morna-addict-menu-bar-button ripple" key={item.id}>
          <Link to={item.path} className="plain-link">
            <div className="morna-addict-menu-bar-button__icon">
              {item.icon}
            </div>
            <div className="morna-addict-menu-bar-button__text">
              {item.title}
            </div>
          </Link>
        </div>
      )
    })}
  </div>
)

export default MenuBar
