import React from "react"
import Layout from "./src/components/Layout"
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import fetch from "isomorphic-fetch"
import "./src/styles/index.scss"

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://api.mornaaddict.org",
    fetch,
  }),
  cache: new InMemoryCache(),
})

const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

const wrapRootElement = ({ element }) => (
  <ApolloProvider client={apolloClient}>{element}</ApolloProvider>
)

export { wrapPageElement, wrapRootElement }
