import React from "react"
import PropTypes from "prop-types"

const View = ({ children }) => {
  return <div className="morna-addict-view">{children}</div>
}

View.propTypes = {
  children: PropTypes.element.isRequired,
}
export default View
